<template>
    <el-container style="background-color: var(--el-bg-color);height: 100%">
        <el-main style="padding: 0">
            <el-container style="height: 100%">
                <el-main style="padding: 0">
                    <el-scrollbar height="100%" ref="scrollbar" always @scroll="scrolling">
                        <div ref="inner">
                            <div class="session" :class="d.type" v-for="d in data">
                                <div style="display: flex;font-size: 17px;margin-bottom: 16px">
                                    <el-icon size="24px" v-if="d.type==='user'">
                                        <User/>
                                    </el-icon>
                                    <div v-else v-html="favicon" style="height: 24px;width: 24px"></div>
                                    <div style="margin-left: 12px">
                                        {{ {user: 'You', assistant: 'DALL·E 3'}[d.type] }}
                                    </div>
                                </div>
                                <div :class="d.isError?'error':''" class="body">
                                    <div class="header" v-if="d.isError">
                                        <el-icon size="14px" style="margin: 3px">
                                            <Warning/>
                                        </el-icon>
                                        <h4 style="margin: 0 0 0 6px">Error</h4>
                                    </div>
                                    <div style="padding-left: 26px">
                                        <store-image v-model="d.content" v-if="d.content && d.imageMeta"></store-image>
                                        <span v-else-if="d.content">{{ d.content }}</span>
                                        <el-icon v-else size="24" class="loading">
                                            <Loading/>
                                        </el-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar>
                </el-main>
                <el-footer height="120px" style="border-top: 1px solid var(--el-border-color)">
                    <div style="display: flex;height: 80px;padding: 19px 0 19px 0">
                        <div style="width: calc(100% - 44px)">
                            <el-input v-model="form.prompt" type="textarea" :autosize="{minRows: 3}" show-word-limit
                                      style="height: 80px" @keydown="handleKeydown" maxlength="4000"></el-input>
                        </div>
                        <div style="width: 36px;padding-left: 8px">
                            <el-icon size="24px" style="cursor: pointer;margin: 6px" @click="reset">
                                <Delete/>
                            </el-icon>
                            <el-button style="width: 36px;height: 36px" @click="submit"
                                       :disabled="!form.prompt" :loading="loading">
                                <template #icon>
                                    <el-icon size="24">
                                        <Promotion/>
                                    </el-icon>
                                </template>
                            </el-button>
                        </div>
                    </div>
                </el-footer>
            </el-container>
        </el-main>
        <el-aside class="aside">
            <div style="display: flex;font-size: 18px;line-height: 18px;margin-top: 20px">
                <div>Settings</div>
                <el-icon style="margin-left: 18px;cursor: pointer;" @click="openSettings">
                    <QuestionFilled/>
                </el-icon>
            </div>
            <el-divider></el-divider>
            <el-form :model="form" ref="form" label-width="0">
                <p>Quality</p>
                <el-form-item prop="quality">
                    <el-select v-model="form.quality" :disabled="loading">
                        <el-option value="standard" label="STANDARD"></el-option>
                        <el-option value="hd" label="HD"></el-option>
                    </el-select>
                </el-form-item>
                <p>Size</p>
                <el-form-item prop="size">
                    <el-select v-model="form.size" :disabled="loading">
                        <el-option value="1024x1024"></el-option>
                        <el-option value="1024x1792"></el-option>
                        <el-option value="1792x1024"></el-option>
                    </el-select>
                </el-form-item>
                <p>Style</p>
                <el-form-item prop="style">
                    <el-select v-model="form.style" :disabled="loading">
                        <el-option value="vivid" label="VIVID"></el-option>
                        <el-option value="natural" label="NATURAL"></el-option>
                    </el-select>
                </el-form-item>
                <el-button text @click="$refs.form.resetFields()">Reset</el-button>
            </el-form>
        </el-aside>
    </el-container>
</template>

<script>
import {getLocalObj, setLocalObj, cleanLocalObj} from "../../libs/storage";
import {deepcopy, generateUuid} from "../../libs/utils";
import {ElMessageBox} from 'element-plus';
import favicon from '../../assets/favicon.svg?raw'
import axios from "ts-axios-new";
import StoreImage from "./StoreImage";

export default {
    name: "DallE",
    components: {StoreImage},
    data() {
        return {
            loading: false, data: [], key: '/dall-e/chat-store', favicon, top: 0, store: 'image',
            form: {
                quality: 'standard',
                size: '1024x1024',
                style: 'vivid',
                prompt: '',
            },
        }
    },
    methods: {
        init() {
            this.data = getLocalObj(this.key, []);
            this.scrollToBottom();

            const request = indexedDB.open('imageStore', 1);
            request.onupgradeneeded = e => {
                const db = e.target.result;
                if (!db.objectStoreNames.contains(this.store)) {
                    db.createObjectStore(this.store, {keyPath: 'id'});
                }
            }
        },
        scrolling({scrollTop}) {
            this.top = scrollTop;
        },
        scrollToBottom() {
            this.$nextTick(_ => {
                const delta = (this.$refs.inner.clientHeight + 300 - this.top) / 5;
                this._scrollToBottom(this.top + delta, delta, 1);
            });
        },
        _scrollToBottom(to, delta, i) {
            setTimeout(_ => {
                this.$refs.scrollbar.setScrollTop(to);
                if (i < 5) {
                    this._scrollToBottom(to + delta, delta, i + 1);
                }
            }, 100);
        },
        openSettings() {
            window.open('https://platform.openai.com/docs/api-reference/images/create')
        },
        reset() {
            ElMessageBox.confirm('确定要清空当前会话吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                cleanLocalObj(this.key);
                this.data = [];
            }).catch(_ => {
            })
        },
        submit() {
            this.loading = true;
            this.data.push({type: 'user', content: this.form.prompt, isError: false});
            const answer = {type: 'assistant', content: '', isError: false};
            this.data.push(answer);
            setLocalObj(this.key, this.data);
            this.scrollToBottom();
            axios.post(`/api/v1/dall-e`, this.form).then(res => {
                const id = generateUuid();
                answer.content = id;
                answer.imageMeta = deepcopy(this.form);
                answer.imageMeta.prompt = res.data.revised_prompt;

                const request = indexedDB.open('imageStore', 1);
                request.onsuccess = e => {
                    const db = e.target.result;
                    const transaction = db.transaction([this.store], 'readwrite');
                    const store = transaction.objectStore(this.store);
                    store.add({id, data: res.data.b64_json});
                }
            }, err => {
                answer.isError = true;
                answer.content = err.response.data.status.message;
            }).then(_ => {
                this.data.splice(this.data.indexOf(answer), 1);
                this.data.push(answer);
                this.form.prompt = '';
                this.loading = false;
                this.scrollToBottom();
                setLocalObj(this.key, this.data);
            });
        },
        handleKeydown(e) {
            if (this.loading)
                return
            if (e.key === 'Enter') {
                if (e.metaKey || e.ctrlKey) {
                    this.form.prompt += '\n';
                } else {
                    e.preventDefault();
                    this.submit();
                }
            }
        }
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>
.aside {
    border-left: 1px solid var(--el-border-color);
    padding: 16px;
    color: var(--el-text-color-primary)
}

.session {
    padding: 16px;
}

.session.user {
    background-color: var(--el-border-color-light);
}

.session.assistant {
    fill: var(--el-text-color-primary);
}

.session .error {
    color: var(--el-color-error);
    border-radius: 0.5rem;
    border: 1px solid var(--el-color-error);
    padding: 16px
}

.session .error .header {
    font-weight: bold;
    display: flex;
}

.session .body {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.loading {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>