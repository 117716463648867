export function getLocalObj(key: string, default_value: any) {
    const value = localStorage.getItem(key);
    if (value) {
        return JSON.parse(value);
    } else {
        return default_value;
    }
}

export function setLocalObj(key: string, obj: any) {
    const value = JSON.stringify(obj);
    localStorage.setItem(key, value);
}

export function cleanLocalObj(key: string) {
    localStorage.removeItem(key)
}
