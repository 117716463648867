import Index from './components/Index.vue';
import ChatGPT from './components/openai/ChatGPT.vue'
import DallE from './components/openai/DallE.vue';

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    },  {
        name: 'ChatGPT',
        path: '/openai/chat-gpt',
        component: ChatGPT,
    },  {
        name: 'DallE',
        path: '/openai/dall-e',
        component: DallE,
    },
]