<template>
    <el-container>
        <el-header height="64px">
            <div style="display: flex">
                <div style="height: 64px">
                    <div style="margin: 16px 0;height: 32px;cursor: pointer;" @click="$router.push({name: 'Index'})"
                         v-html="favicon" class="brand"></div>
                </div>
                <div style="padding: 0 10px;line-height: 64px;font-size: 20px;color: var(--el-text-color-regular);">
                    AI Playground
                    <el-divider direction="vertical" style="font-size: 32px"></el-divider>
                </div>
            </div>
            <div class="dark-switcher">
                <el-switch style="margin: 16px" v-model="dark" @change="changeDark"
                           active-action-icon="Moon" inactive-action-icon="Sunny"></el-switch>
            </div>
        </el-header>
        <el-container style="background-color: var(--el-border-color-extra-light)" :style="{height: height + 'px'}">
            <aside-nav ref="nav">
                <aside-group title="OpenAI">
                    <aside-item icon="ChatDotSquare" title="Chat-GPT" index="ChatGPT"></aside-item>
                    <aside-item icon="PictureFilled" title="Dall-E" index="DallE"></aside-item>
                </aside-group>
            </aside-nav>
            <el-main :style="{padding: $route.name === 'Tag'?'0':'var(--el-main-padding)'}">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import {useDark, useToggle} from '@vueuse/core';
import AsideNav from "./base/AsideNav.vue";
import AsideGroup from "./base/AsideGroup.vue";
import AsideItem from "./base/AsideItem.vue";
import axios from "ts-axios-new";
import favicon from './assets/favicon.svg?raw'

export default {
    name: 'App',
    components: {AsideGroup, AsideItem, AsideNav},
    data() {
        return {
            dark: window.matchMedia('(prefers-color-scheme: dark)').matches, favicon,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight - 64,
            media: window.matchMedia('(prefers-color-scheme: dark)'),
            country_list: [], cdn_url: import.meta.env.VITE_CDN_URL,
            image_module_enum: {1: 'Library', 2: 'Daily', 3: 'Story'},
            user: {
                user_id: '',
                avatar: '',
                name: '',
                email: '',
                access: [],
            },
        }
    },
    methods: {
        changeDark() {
            useToggle(useDark())(this.dark);
        },
        handleScroll() {
            this.height = document.documentElement.clientHeight - 64;
            this.width = document.documentElement.clientWidth;
        },
    },
    mounted() {
        window.onresize = _ => {
            this.handleScroll();
        };
        this.media.addEventListener('change', e => {
            this.dark = e.matches;
            this.changeDark();
        });
        this.changeDark();
        axios.get('https://console.plutodesk.com/api/v1/country').then(res => {
            this.country_list = res.data.data.countryList;
        });
    },
}
</script>
<style>
html, body {
    margin: 0;
    padding: 0;
}

.el-header {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    border-bottom: solid 1px var(--el-border-color-light);
    background-color: var(--el-bg-color);
}

.brand svg {
    height: 32px;
    fill: var(--el-text-color-primary);
}
</style>
<style scoped>
.dark-switcher ::v-deep(.el-switch__action) {
    color: #000 !important;
}

.dark-switcher ::v-deep(.el-switch.is-checked .el-switch__core) {
    background-color: #444;
    border-color: #444;
}
</style>