<template>
    <img style="width: 300px" ref="image"/>
</template>

<script>
export default {
    name: "StoreImage",
    props: {
        modelValue: String
    },
    methods: {
        init() {
            const request = indexedDB.open('imageStore', 1);
            const store = 'image';
            request.onsuccess = e => {
                const db = e.target.result;
                const transaction = db.transaction([store], 'readwrite');
                const storeObject = transaction.objectStore(store);
                storeObject.get(this.modelValue).onsuccess = e => {
                    this.$refs.image.src = `data:image;base64,${e.target.result.data}`;
                }
            }
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>